import React, { useEffect, useState } from 'react';
import './Cards.css';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import { API_URL } from '../utils';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import UnblockIcon from '@mui/icons-material/LockOpen';
import Stack from '@mui/material/Stack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { saveAs } from 'file-saver';
// Card Component
const SummaryCard = ({ title, count }) => {
  return (
    <div className="card">
      <h2>{title}</h2>
      <p>{count}</p>
    </div>
  );
};

const makeStyle = (status) => {
  switch (status) {
    case "Active":
      return { background: "green", color: "green" };
    
    case "Banned":
      return { background: "#ff4d4d", color: "white" };
    case "Pending Verification":
      return { background: "#ffd7008f", color: "#ff8c00" };
    default:
      return { background: "#59bfff", color: "white" };
  }
};

// EditableField Component
const EditableField = ({ label, value, onChange }) => {
  return (
    <div>
      <input
        type="text"
        placeholder={label}
        value={value}
        onChange={onChange}
        style={{ width: "100%" }}
      />
    </div>
  );
};

// Main Component
const AllUsersPage = () => {
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [userData, setUserData] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [phone, setPhone] = useState("");
  const [rerender, setRerender] = useState(true);
  const [editMode, setEditMode] = useState(null);
  const [message, setMessage] = useState(''); 
  const [editedData, setEditedData] = useState({});
  const apiBaseUrl = API_URL;

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/super-admin/all-users`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = async () => {
    let response;
    try {
      // Format dates to DD/MM/YYYY
      const formatDate = (date) => {
        if (!date) return '';
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
      };
      
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
  
      if (email) {
        response = await axios.post(
          `${apiBaseUrl}/super-admin/search-user-by-email`,
          { email },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("user_token")}`,
            },
          }
        );
        setUserData([response.data]);
        setMessage(''); // Clear message if data is found
      } else if (phone) {
        response = await axios.post(
          `${apiBaseUrl}/super-admin/search-user-by-phone`,
          { phone },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("user_token")}`,
            },
          }
        );
        setUserData([response.data]);
        setMessage(''); // Clear message if data is found
      } else if (name) {
        const [firstName, lastName] = name.split(" ");
        response = await axios.post(
          `${apiBaseUrl}/super-admin/search-user-by-name`,
          { firstName, lastName },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("user_token")}`,
            },
          }
        );
        setUserData(response.data);
        setMessage(''); // Clear message if data is found
      } else if (userId) {
        response = await axios.post(
          `${apiBaseUrl}/super-admin/search-user-by-id`,
          { userId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("user_token")}`,
            },
          }
        );
        setUserData([response.data]);
        setMessage(''); // Clear message if data is found
      } else if (formattedStartDate && formattedEndDate) {
        response = await axios.get(
          `${apiBaseUrl}/super-admin/users-by-date`,
          {
            params: {
              startDate: formattedStartDate,
              endDate: formattedEndDate
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("user_token")}`,
            }
          }
        );
  
        // Check if data is empty and display appropriate message
        if (response.data.length === 0) {
          setMessage("No users found in the selected date range.");
          setUserData([]); // Clear previous data if needed
        } else {
          setUserData(response.data);
          setMessage(''); // Clear message if data is found
        }
      } else {
        fetchData(); // Default fetch if no specific search query is provided
      }
  
      setRerender(false);
      setTimeout(() => {
        setRerender(true);
      }, 1);
    } catch (error) {
      if (axios.isAxiosError(error)){

        if (error.response.status===404){
        
          setUserData([])
          setRerender(false);
          setTimeout(() => {
            setRerender(true);
          }, 1);

        }
      }else{

        console.error("Some unknown error ocured searching user:", error);
        setMessage("Error searching for users. Please try again.");
      }
    }
  };
  
  

  const handleReset = () => {
    setStatus('');
    setStartDate('');
    setEndDate('');
    setEmail('');
    setName('');
    setUserId('');
    setPhone('');
    fetchData();
  };

  const handleEditClick = (row) => {
    setEditMode(row.userId);
    setEditedData({ ...row });
  };

  const handleSaveClick = async (userId) => {
    try {
      await axios.put(
        `${apiBaseUrl}/super-admin/edit-user/${userId}`,
        {
          lastName: editedData.lastName,
          firstName: editedData.firstName,
          email: editedData.email,
          phone: editedData.phone
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      alert('User details updated successfully!');
      setEditMode(null);
      fetchData();
    } catch (error) {
      alert('Error updating user details.');
      console.error("Error updating user details:", error);
    }
  };

  const handleCancelClick = () => {
    setEditMode(null);
    setEditedData({});
  };
  const renderVerificationStatus = (isDocumentVerified, photos) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {/* Document Verification Status */}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        
          <span style={{ marginRight: '5px' }}>Document</span>
          {isDocumentVerified ? (
            <CheckCircleIcon style={{ color: 'green' }} />
          ) : (
            <CancelIcon style={{ color: 'red' }} />
          )}
        </div>

        {/* Photo Verification Status */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {photos?.map((photo, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '5px',
              }}
            >
              <span style={{ marginRight: '5px' }}>Photo {index + 1}</span>
              {photo.verified ? (
                <CheckCircleIcon style={{ color: 'green' }} />
              ) : (
                <CancelIcon style={{ color: 'red' }} />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleBlockUnblockUser = async (userId) => {
    try {
      const user = userData.find(user => user.userId === userId);
      const endpoint = user.status === "blocked" ? "activate-user" : "block-user";
      await axios.put(`${API_URL}/super-admin/${endpoint}/${userId}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        }
      });
      fetchData();
    } catch (error) {
      console.error("Error blocking/unblocking user:", error);
    }
  };

  const handleDeleteUser = async (userId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this user?");
    if (confirmDelete) {
      try {
        await axios.delete(
          `${apiBaseUrl}/super-admin/delete-user/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("user_token")}`,
            },
          }
        );
        alert('User deleted successfully!');
        fetchData();
      } catch (error) {
        alert('Error deleting user.');
        console.error("Error deleting user:", error);
      }
    }
  };
  const handleDownload = async () => {
    try {
      // Check if both startDate and endDate are provided
      if (!startDate || !endDate) {
        alert("Both startDate and endDate are required for downloading user details.");
        return;
      }
  
      // Format dates to DD/MM/YYYY for the API
      const formatDate = (date) => {
        if (!date) return '';
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
      };
  
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
  
      // Validate formatted dates
      if (!formattedStartDate || !formattedEndDate) {
        alert("Invalid date format.");
        return;
      }
  
      const response = await axios.get(
        `${apiBaseUrl}/super-admin/download-users-by-date`,
        {
          params: {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
          responseType: 'blob', // Ensure the response is a Blob (binary data)
        }
      );
  
      // Extract the filename from the response headers if available
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'user-details.pdf'; // Default file name
      if (contentDisposition && contentDisposition.includes('filename=')) {
        fileName = contentDisposition.split('filename=')[1].trim();
      }
  
      // Use FileSaver to save the PDF file
      const blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, fileName);
  
    } catch (error) {
      if (axios.isAxiosError(error)){
        if (error.response.status===404){
          alert("No Data Available to Download")
        }
      }else{

        console.error("Error downloading PDF:", error);
        alert('Failed to download user details.');
      }
    }
  };
  
  return (
    <div className="MainDash">
      <h1>All Users</h1>
      <div className="select-bar-container">
        <div className="select-bar">
          <select value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value="">Select Status</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
            <option value="blocked">Banned</option>
          </select>

          <input
            type="text"
            placeholder="Search By Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />

          <input
            type="text"
            placeholder="Search By Email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />

          <input
            type="text"
            placeholder="Search By User ID"
            value={userId}
            onChange={(e) => setUserId(e.currentTarget.value)}
          />

          <input
            type="text"
            placeholder="Search By Phone"
            value={phone}
            onChange={(e) => setPhone(e.currentTarget.value)}
          />

          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />

          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />

          <div className="button-group">
            <button onClick={handleSearch} className="search-button">Search</button>
            <button onClick={handleReset} className="reset-button">Reset</button>
          </div>
        </div>

        <button className="download-button" onClick={handleDownload}>
          Download All Users Details
        </button>
       
      </div>
      <div className="Table">
        <h3>Users List</h3>
        <div className="table-container">
          <TableContainer
            component={Paper}
            style={{
              boxShadow: "0px 13px 20px 0px #80808029",
              overflowY: "auto",
              height: 400,
            }}
          >
            {rerender && (
              <Table stickyHeader sx={{ minWidth: 800 }} aria-label="simple table">
                <TableHead style={{ backgroundColor: "#1F618D" }}>
                  <TableRow>
                    <TableCell align="left">Profile Picture</TableCell>
                    <TableCell align="left">User ID</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Mobile</TableCell>
                    <TableCell align="left">Age</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Verification Status</TableCell>
                    <TableCell align="left">Gender</TableCell>
                    <TableCell align="left">Subscription</TableCell>
                    <TableCell align="left">Block/Unblock</TableCell>
                    <TableCell align="left">Edit</TableCell>
                    <TableCell align="left">Delete User</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ color: "white" }}>
                  {userData?.filter((row) => {
                    if (status.length !== 0) {
                      return row.status.toLowerCase() === status.toLowerCase();
                    } else {
                      return true;
                    }
                  })?.map((row) => (
                    <TableRow
                      key={row.userId}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      
                      <TableCell align="left">
                        <img
                          src={row.photos?.[0]?.url || 'defaultProfilePictureUrl'}
                          alt="Profile"
                          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                        />
                      </TableCell>
                      <TableCell align="left">{row.userId}</TableCell>
                      <TableCell component="th" scope="row">
                        {editMode === row.userId ? (
                          <EditableField
                            label="Name"
                            value={`${editedData.firstName || ''} ${editedData.lastName || ''}`}
                            onChange={(e) => {
                              const [firstName, lastName] = e.target.value.split(" ");
                              setEditedData((prevData) => ({
                                ...prevData,
                                firstName: firstName,
                                lastName: lastName,
                              }));
                            }}
                          />
                        ) : (
                          `${row.firstName} ${row.lastName}`
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {editMode === row.userId ? (
                          <EditableField
                            label="Email"
                            value={editedData.email}
                            onChange={(e) => setEditedData({ ...editedData, email: e.target.value })}
                          />
                        ) : (
                          row.email
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {editMode === row.userId ? (
                          <EditableField
                            label="Mobile"
                            value={editedData.phone}
                            onChange={(e) => setEditedData({ ...editedData, phone: e.target.value })}
                          />
                        ) : (
                          row.phone
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {editMode === row.userId ? (
                          <EditableField
                            label="Age"
                            value={editedData.age}
                            onChange={(e) => setEditedData({ ...editedData, age: e.target.value })}
                          />
                        ) : (
                          row.age
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <span className="status" style={makeStyle(row.status)}>{row.status}</span>
                      </TableCell>
                      <TableCell align="left" key={row.id}>
          {renderVerificationStatus(row.isDocumentVerified, row.photos)}
        </TableCell>
                      <TableCell align="left">{row.gender}</TableCell>
                      <TableCell align="left">{row.hasSubscription ? "Yes" : "No"}</TableCell>
                      <TableCell align="left">
                        <Button onClick={() => handleBlockUnblockUser(row.userId)}>
                          {row.status.toLowerCase() === "blocked" ? <UnblockIcon /> : <BlockIcon />}
                        </Button>
                      </TableCell>
                      <TableCell align="left">
                        {editMode === row.userId ? (
                          <Stack direction="column" gap={1}> {/* Use Stack component */}
                            <Button 
                              onClick={() => handleSaveClick(row.userId)}
                              variant="contained"
                              color="primary"
                              startIcon={<SaveIcon />}
                            >
                              Save
                            </Button>
                            <Button 
                              onClick={handleCancelClick} 
                              variant="contained"
                              color="secondary"
                              startIcon={<CancelIcon />}
                            >
                              Cancel
                            </Button>
                          </Stack>
                        ) : (
                          <Button onClick={() => handleEditClick(row)}>
                            <EditIcon />
                          </Button>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <Button onClick={() => handleDeleteUser(row.userId)}>
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default AllUsersPage;
