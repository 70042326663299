import React, { useEffect, useState } from 'react';
//import './UserTable.css';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import axios from "axios";
import { API_URL } from '../utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';

const makeStyle = (status) => {
  switch (status) {
    case "Approved":
      return { background: "green", color: "green" };
    case "Rejected":
      return { background: "#ff4d4d", color: "white" };
    case "Pending":
      return { background: "#ffd7008f", color: "#ff8c00" };
    default:
      return { background: "#59bfff", color: "white" };
  }
};

const UserTable = () => {
  const [status, setStatus] = useState('Pending');
  const [userData, setUserData] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [phone, setPhone] = useState("");
  const apiBaseUrl = API_URL;
  const navigate = useNavigate();
  const fetchData = async () => {
    let endpoint = '';
    switch (status) {
      case 'Pending':
        endpoint = 'pending-verification-photos';
        break;
      case 'Approved':
        endpoint = 'approved-photos';
        break;
      case 'Rejected':
        endpoint = 'rejected-photos';
        break;
      default:
        return;
    }

    try {
      const response = await axios.get(`${apiBaseUrl}/super-admin/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (status) {
      fetchData();
    }
  }, [status]);

  const handleSearch = async () => {
    let response;
    try {
      if (email) {
        response = await axios.post(
          `${apiBaseUrl}/super-admin/search-user-by-email`,
          { email },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("user_token")}`,
            },
          }
        );
        setUserData([response.data]);
      } else if (phone) {
        response = await axios.post(
          `${apiBaseUrl}/super-admin/search-user-by-phone`,
          { phone },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("user_token")}`,
            },
          }
        );
        setUserData([response.data]);
      } else if (name) {
        const [firstName, lastName] = name.split(" ");
        response = await axios.post(
          `${apiBaseUrl}/super-admin/search-user-by-name`,
          { firstName, lastName },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("user_token")}`,
            },
          }
        );
        setUserData(response.data);
      } else if (userId) {
        response = await axios.post(
          `${apiBaseUrl}/super-admin/search-user-by-id`,
          { userId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("user_token")}`,
            },
          }
        );
        setUserData([response.data]);
      }
    } catch (error) {
      console.error("Error searching user:", error);
    }
  };

  const handleReset = () => {
    setStatus('');
    setEmail('');
    setName('');
    setUserId('');
    setPhone('');
    setUserData([]);
  };

  const renderVerificationStatus = (isDocumentVerified, photos) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
          <span style={{ marginRight: '5px' }}>Document</span>
          {isDocumentVerified ? (
            <CheckCircleIcon style={{ color: 'green' }} />
          ) : (
            <CancelIcon style={{ color: 'red' }} />
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {photos?.map((photo, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '5px',
              }}
            >
              <span style={{ marginRight: '5px' }}>Photo {index + 1}</span>
              {photo.verified ? (
                <CheckCircleIcon style={{ color: 'green' }} />
              ) : (
                <CancelIcon style={{ color: 'red' }} />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };
  const handleViewRequest = (userId) => {
    navigate(`/verification-request/${userId}`);
  };

  return (
    <div className="MainDash">
      <h1>Photo Verification </h1>
      <div className="select-bar-container">
        <div className="select-bar">
          <select value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value="">Select Status</option>
            <option value="Pending">Pending</option>
            <option value="Approved">Approved</option>
            <option value="Rejected">Rejected</option>
          </select>

          <input
            type="text"
            placeholder="Search By Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />

          <input
            type="text"
            placeholder="Search By Email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />

          <input
            type="text"
            placeholder="Search By User ID"
            value={userId}
            onChange={(e) => setUserId(e.currentTarget.value)}
          />

          <input
            type="text"
            placeholder="Search By Phone"
            value={phone}
            onChange={(e) => setPhone(e.currentTarget.value)}
          />

          <div className="button-group">
            <button onClick={handleSearch} className="search-button">Search</button>
            <button onClick={handleReset} className="reset-button">Reset</button>
          </div>
        </div>
      </div>
      <div className="Table">
        <h3>Users List</h3>
        <div className="table-container">
          <TableContainer
            component={Paper}
            style={{
              boxShadow: "0px 13px 20px 0px #80808029",
              overflowY: "auto",
              height: 400,
            }}
          >
            <Table stickyHeader sx={{ minWidth: 800 }} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#1F618D" }}>
                <TableRow>
                  <TableCell align="left">Profile Picture</TableCell>
                  <TableCell align="left">User ID</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Mobile</TableCell>
                  
                  <TableCell align="left">Verification Status</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ color: "white" }}>
                {userData.map((row) => (
                  <TableRow key={row.userId} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="left">
                      <img
                        src={row.photos?.[0]?.url || 'defaultProfilePictureUrl'}
                        alt="Profile"
                        style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                      />
                    </TableCell>
                    <TableCell align="left">{row.userId}</TableCell>
                    <TableCell component="th" scope="row">
                      {`${row.firstName} ${row.lastName}`}
                    </TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.phone}</TableCell>
                   
                    <TableCell align="left" key={row.id}>
                      {renderVerificationStatus(row.isDocumentVerified, row.photos)}
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleViewRequest(row.userId)}
                      >
                        View Request
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default UserTable;
