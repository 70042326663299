import React from "react";


import "./RightSide.css";

const RightSide = () => {
  
};

export default RightSide;
