import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { API_URL } from '../utils';

const DocumentVerificationRequest = () => {
  const { userId } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiBaseUrl = API_URL;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/super-admin/all-users`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('user_token')}`,
          },
        });

        const user = response.data.find((user) => user.userId === userId);
        if (user) {
          setUserData(user);
        } else {
          console.error('User not found.');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId, apiBaseUrl]);

  const handleVerifyDocument = async (documentId) => {
    try {
      await axios.post(
        `${apiBaseUrl}/super-admin/verify-document/${userId}/${documentId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('user_token')}`,
          },
        }
      );
      alert('Document verified successfully!');
      setUserData((prev) => ({
        ...prev,
        documents: prev.documents.map((doc) =>
          doc._id === documentId ? { ...doc, verificationStatus: 'Approved', verified: true } : doc
        ),
      }));
    } catch (error) {
      console.error('Error verifying document:', error);
      alert('Failed to verify document.');
    }
  };

  const handleRejectDocument = async (documentId) => {
    try {
      await axios.post(
        `${apiBaseUrl}/super-admin/reject-document/${userId}/${documentId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('user_token')}`,
          },
        }
      );
      alert('Document rejected successfully.');
      // Update state to remove the rejected document
      setUserData((prev) => ({
        ...prev,
        documents: prev.documents.filter((doc) => doc._id !== documentId),
      }));
    } catch (error) {
      console.error('Error rejecting document:', error);
      alert('Failed to reject document.');
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  if (!userData) {
    return <div>No document data available.</div>;
  }

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Document Verification Request for {userData.firstName} {userData.lastName}
      </Typography>
      <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h6" component="h2">
          User Details:
        </Typography>
        <Typography>
          <strong>User ID:</strong> {userData.userId}
        </Typography>
        <Typography>
          <strong>Email:</strong> {userData.email}
        </Typography>
        <Typography>
          <strong>Phone:</strong> {userData.phone}
        </Typography>
        <Typography>
          <strong>Gender:</strong> {userData.gender}
        </Typography>
      </Paper>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Documents:
        </Typography>
        <Grid container spacing={3}>
          {userData.documents?.map((document) => (
            <Grid item xs={12} sm={6} md={4} key={document._id}>
              <div style={{ textAlign: 'center' }}>
                <Typography>
                  <strong>Document Name:</strong> {document.name}
                </Typography>
                <Typography>
                  <strong>Uploaded Date:</strong> {new Date(document.uploadedAt).toLocaleDateString()}
                </Typography>
                {document.url && (
                  <a href={document.url} target="_blank" rel="noopener noreferrer">
                    View Document
                  </a>
                )}
                <div style={{ marginTop: '10px' }}>
                  {document.verified ? (
                    <Typography color="primary" variant="body1">
                      Verified
                    </Typography>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleVerifyDocument(document._id)}
                        startIcon={<CheckCircleIcon />}
                        style={{ marginRight: '10px' }}
                      >
                        Verify
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleRejectDocument(document._id)}
                        startIcon={<CancelIcon />}
                      >
                        Reject
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Button variant="contained" onClick={() => window.history.back()} style={{ marginTop: '20px' }}>
        Back to User Table
      </Button>
    </div>
  );
};

export default DocumentVerificationRequest;
