import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Sidebar.css";
//import Logo from "../imgs/logo.png";
import { UilSignOutAlt, UilHome, UilBars } from "@iconscout/react-unicons";
import { motion } from "framer-motion";

const Sidebar = ({ handleLogout }) => {
  const Catalog = [
    {
      name: "User Manager",
      children: [
        "All Users",
        "Verify Photo",
        "Verify Document",
        "Default Photo",
      ],
    },
    {
      name: "Matches Management",
      children: ["View Matches", "Chat History"],
    },
    {
      name: "Plan Management",
      children: ["Manage Plans", "Activate Plan", "Plan Status"],
    },
    {
      name: "Sparkel Management",
      children: [
        "Create Sparkel",
        "Activate/Deactivate",
        "Sparkel Status",
      ],
    },
    {
      name: "Query Management",
      children: ["Queries Data"],
    },
  ];

  const [selected, setSelected] = useState(null);
  const [expanded, setExpanded] = useState(true); // Start expanded
  const navigate = useNavigate();

  const sidebarVariants = {
    expanded: {
      left: "0",
    },
    collapsed: {
      left: "-100%", // Adjust based on your layout
    },
  };

  return (
    <>
      <div className="bars" onClick={() => setExpanded(!expanded)}>
        <UilBars />
      </div>

      <motion.div
        className="sidebar"
        variants={sidebarVariants}
        animate={expanded ? "expanded" : "collapsed"}
      >
        <div className="logo">
         
          <span>
            Hi<span>sa</span>thi
          </span>
        </div>

        <div className="menu">
          <div className="menuItem" onClick={() => navigate("/dashboard")}>
            <UilHome /> <span>Home</span>
          </div>
          {Catalog.map((item, index) => (
            <div
              className={selected === index ? "menuItem active" : "menuItem"}
              key={index}
              onClick={() => setSelected(selected === index ? null : index)}
            >
              <span>{item.name}</span>
              {selected === index && (
                <ul>
                  {item.children.map((child, childIndex) => (
                    <li key={childIndex}>
                      <Link
                        to={
                          child === "View Matches"
                            ? "/view-matches"
                            : child === "Manage Plans"
                            ? "/manage-plans"
                            : child === "All Users"
                            ? "/all-users"
                            : child === "Plan Status"
                            ? "/plan-status"
                            : child === "Sparkel Status"
                            ? "/sparkel-status"
                            : child === "Verify Photo"
                            ? "/user-verification-requests"
                            : child === "Verify Document"
                            ? "/user-verificationdocument-requests"
                            : child === "Default Photo"
                            ? "/default-photo"
                            : child === "Create Sparkel"
                            ? "/manage-sparkel"
                            : child === "Chat History"
                            ? "/chat-history"
                            : child === "Activate/Deactivate"
                            ? "/sparkle-activate-deactivate"
                            : child === "Activate Plan"
                            ? "/plan-activate-deactivate"
                            : child === "Queries Data"
                            ? "/querry"
                            : ""
                        }
                      >
                        {child}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
          <div className="menuItem" onClick={handleLogout}>
            <UilSignOutAlt />
            <span>Logout</span>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar;
