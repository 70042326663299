import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';

// Base URL of your API
const API_URL = "https://admin.hisathi.com";

const SparkleStatus = () => {
  const [activeSparkleUsers, setActiveSparkleUsers] = useState([]);
  const [inactiveSparkleUsers, setInactiveSparkleUsers] = useState([]);
  const [expiredSparkleUsers, setExpiredSparkleUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSparkleStatus();
  }, []);

  const fetchSparkleStatus = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem("user_token");

      // Fetch sparkle statuses
      const activeSparkleResponse = await axios.get(`${API_URL}/super-admin/active-sparkle-plan-users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const inactiveSparkleResponse = await axios.get(`${API_URL}/super-admin/inactive-sparkle-plan-users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const expiredSparkleResponse = await axios.get(`${API_URL}/super-admin/expired-sparkle-plan-users`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setActiveSparkleUsers(activeSparkleResponse.data.users || []);
      setInactiveSparkleUsers(inactiveSparkleResponse.data.users || []);
      setExpiredSparkleUsers(expiredSparkleResponse.data.users || []);
    } catch (err) {
      console.error("Error fetching sparkle statuses:", err);
      setError("Failed to load sparkle statuses. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '16px' }}>Sparkle Status</h2>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </div>
      ) : error ? (
        <Alert severity="error" style={{ marginBottom: '16px' }}>
          {error}
        </Alert>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <CollapsibleSection title="Active Sparkle Users" users={activeSparkleUsers} status="active" />
          <CollapsibleSection title="Inactive Sparkle Users" users={inactiveSparkleUsers} status="inactive" />
          <CollapsibleSection title="Expired Sparkle Users" users={expiredSparkleUsers} status="expired" />
        </div>
      )}
    </div>
  );
};

// Reusable component for collapsible sections with user data
const CollapsibleSection = ({ title, users, status }) => {
  const [isOpen, setIsOpen] = useState(false);

  const sectionStyles = {
    border: '1px solid',
    padding: '10px',
    borderRadius: '8px',
    borderColor: status === 'active' ? '#4caf50' : status === 'inactive' ? '#ff9800' : '#f44336',
  };

  const tableContainerStyles = {
    maxHeight: '300px',
    overflowY: 'auto',
  };

  const tableStyles = {
    width: '100%',
    marginTop: '10px',
    borderCollapse: 'collapse',
  };

  const headerStyles = {
    backgroundColor: '#f5f5f5',
  };

  const cellStyles = {
    border: '1px solid #ddd',
    padding: '8px',
  };

  const statusStyles = {
    color: status === 'active' ? '#4caf50' : status === 'inactive' ? '#ff9800' : '#f44336',
    fontWeight: 'bold',
  };

  return (<div className="MainDash">
    <div style={sectionStyles}>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        style={{
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 'bold',
          color: statusStyles.color,
        }}
      >
        {isOpen ? `Hide ${title}` : `Show ${title}`}
      </Button>
      <Collapse in={isOpen}>
        <div style={tableContainerStyles}>
          {users.length === 0 ? (
            <p style={{ fontStyle: 'italic', color: '#888' }}>No {title.toLowerCase()} available.</p>
          ) : (
            <table style={tableStyles}>
              <thead>
                <tr style={headerStyles}>
                  <th style={cellStyles}>Name</th>
                  <th style={cellStyles}>Email</th>
                  <th style={cellStyles}>Plan</th>
                  <th style={cellStyles}>Status</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user._id}>
                    <td style={cellStyles}>{user.name || 'N/A'}</td>
                    <td style={cellStyles}>{user.email || 'N/A'}</td>
                    <td style={cellStyles}>{user.plan || 'N/A'}</td>
                    <td style={{ ...cellStyles, ...statusStyles }}>
                      {status.charAt(0).toUpperCase() + status.slice(1)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </Collapse>
    </div>
    </div>
  );
};

export default SparkleStatus;
