import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { API_URL } from '../utils';

const VerificationRequest = () => {
  const { userId } = useParams();
  const [userData, setUserData] = useState(null);
  const apiBaseUrl = API_URL;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/super-admin/all-users`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('user_token')}`,
          },
        });

        const user = response.data.find((user) => user.userId === userId);
        if (user) {
          setUserData(user);
        } else {
          console.error('User not found.');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [userId, apiBaseUrl]);

  const handleVerify = async (photoId) => {
    try {
      await axios.put(
        `${apiBaseUrl}/super-admin/verify-photo/${userId}/${photoId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('user_token')}`,
          },
        }
      );
      alert('Photo verified successfully.');
      // Update the UI to reflect the verified status
      setUserData((prevData) => ({
        ...prevData,
        photos: prevData.photos.map((photo) =>
          photo._id === photoId ? { ...photo, verificationStatus: 'approved' } : photo
        ),
      }));
    } catch (error) {
      console.error('Error verifying photo:', error);
      alert('Failed to verify photo.');
    }
  };

  const handleReject = async (photoId) => {
    try {
      await axios.put(
        `${apiBaseUrl}/super-admin/reject-photo/${userId}/${photoId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('user_token')}`,
          },
        }
      );
      alert('Photo rejected successfully.');
      // Update the UI to reflect the rejected status
      setUserData((prevData) => ({
        ...prevData,
        photos: prevData.photos.map((photo) =>
          photo._id === photoId ? { ...photo, verificationStatus: 'rejected' } : photo
        ),
      }));
    } catch (error) {
      console.error('Error rejecting photo:', error);
      alert('Failed to reject photo.');
    }
  };

  if (!userData) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="VerificationRequest" style={{ padding: '20px' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Verification Request for {userData.firstName} {userData.lastName}
      </Typography>
      <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h6" component="h2">
          User Details:
        </Typography>
        <Typography>
          <strong>User ID:</strong> {userData.userId}
        </Typography>
        <Typography>
          <strong>Email:</strong> {userData.email}
        </Typography>
        <Typography>
          <strong>Phone:</strong> {userData.phone}
        </Typography>
        <Typography>
          <strong>Gender:</strong> {userData.gender}
        </Typography>
      </Paper>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Photos:
        </Typography>
        <Grid container spacing={3}>
          {userData.photos?.map((photo, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <div style={{ textAlign: 'center' }}>
                <a href={photo.url} target="_blank" rel="noopener noreferrer">
                  <img
                    src={photo.url}
                    alt={`Photo ${index + 1}`}
                    style={{
                      width: '150px',
                      height: '150px',
                      borderRadius: '5px',
                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                      cursor: 'pointer',
                    }}
                  />
                </a>
                <div style={{ marginTop: '10px' }}>
                  {photo.verificationStatus === 'approved' ? (
                    <Typography color="primary" variant="body1">
                      Verified
                    </Typography>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleVerify(photo._id)}
                        startIcon={<CheckCircleIcon />}
                        style={{ marginRight: '10px' }}
                      >
                        Verify
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleReject(photo._id)}
                        startIcon={<CancelIcon />}
                      >
                        Reject
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Button
        variant="contained"
        onClick={() => window.history.back()}
        style={{ marginTop: '20px' }}
      >
        Back to User Table
      </Button>
    </div>
  );
};

export default VerificationRequest;
