import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../utils'; // Set your base API URL here
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const SparklePlanActivateDeactivate = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [userId, setUserId] = useState('');
  const [phone, setPhone] = useState('');
  const [userData, setUserData] = useState([]);
  const [plans, setPlans] = useState([]);
  const [selectedSparkleId, setSelectedSparkleId] = useState(null);

  useEffect(() => {
    fetchData(); // Fetch all users initially
    fetchSparkleLists(); // Fetch sparkle lists
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/super-admin/all-users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('user_token')}`,
        },
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchSparkleLists = async () => {
    try {
      const token = localStorage.getItem('user_token');
      const response = await axios.get(`${API_URL}/super-admin/sparkle-lists`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPlans(response.data.sparkles || []);
    } catch (error) {
      console.error('Error fetching sparkle lists:', error);
    }
  };

  const handleSearch = async () => {
    let response;
    try {
      if (email) {
        response = await axios.post(
          `${API_URL}/super-admin/search-user-by-email`,
          { email },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('user_token')}`,
            },
          }
        );
        setUserData([response.data]);
      } else if (phone) {
        response = await axios.post(
          `${API_URL}/super-admin/search-user-by-phone`,
          { phone },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('user_token')}`,
            },
          }
        );
        setUserData([response.data]);
      } else if (name) {
        const [firstName, lastName] = name.split(' ');
        response = await axios.post(
          `${API_URL}/super-admin/search-user-by-name`,
          { firstName, lastName },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('user_token')}`,
            },
          }
        );
        setUserData(response.data);
      } else if (userId) {
        response = await axios.post(
          `${API_URL}/super-admin/search-user-by-id`,
          { userId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('user_token')}`,
            },
          }
        );
        setUserData([response.data]);
      }
    } catch (error) {
      console.error('Error searching user:', error);
    }
  };

  const handleReset = () => {
    setEmail('');
    setName('');
    setUserId('');
    setPhone('');
    fetchData(); // Reset data to show all users
  };

  const handleSelectPlan = (userId, planId) => {
    setUserData((prevUserData) =>
      prevUserData.map((user) =>
        user.userId === userId ? { ...user, selectedPlanId: planId } : user
      )
    );
    setSelectedSparkleId(planId);
  };

  const handleTogglePlan = async (userId, sparkleId, isActive) => {
    try {
      const token = localStorage.getItem('user_token');
      const user = userData.find((u) => u.userId === userId);

      if (!user || !user._id) {
        console.error('User _id is invalid or missing');
        return;
      }

      if (isActive) {
        await axios.post(
          `${API_URL}/super-admin/user/sparkle/deactivate`,
          { _id: user._id, sparkleId },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } else {
        await axios.post(
          `${API_URL}/super-admin/user/sparkle/activate`,
          { _id: user._id, sparkleId },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }

      setPlans((prevPlans) =>
        prevPlans.map((sparkle) =>
          sparkle._id === sparkleId ? { ...sparkle, active: !isActive } : sparkle
        )
      );
    } catch (error) {
      console.error(`Error toggling sparkle plan for the user: ${error}`);
    }
  };

  return (
    <div className="MainDash">
    <div className="sparkle-container">
      <h1>Sparkle - Activate/Deactivate Sparkel</h1>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search By Name"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
        />
        <input
          type="text"
          placeholder="Search By Email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
        <input
          type="text"
          placeholder="Search By User ID"
          value={userId}
          onChange={(e) => setUserId(e.currentTarget.value)}
        />
        <input
          type="text"
          placeholder="Search By Phone"
          value={phone}
          onChange={(e) => setPhone(e.currentTarget.value)}
        />
        <div className="button-group">
          <button onClick={handleSearch} className="search-button">
            Search
          </button>
          <button onClick={handleReset} className="reset-button">
            Reset
          </button>
        </div>
      </div>
      <div className="table-container">
        <h3>User List</h3>
        <TableContainer
          component={Paper}
          style={{
            boxShadow: '0px 13px 20px 0px #80808029',
            overflowY: 'auto',
            height: '400px',
          }}
        >
          <Table stickyHeader sx={{ minWidth: 800 }} aria-label="simple table">
            <TableHead style={{ backgroundColor: '#1F618D' }}>
              <TableRow>
                <TableCell align="left">Profile Picture</TableCell>
                <TableCell align="left">User ID</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Phone</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Select Plan</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData.map((user) => (
                <TableRow key={user.userId}>
                  <TableCell align="left">
                    <img
                      src={user.photos?.[0]?.url || '/path/to/defaultProfilePictureUrl.jpg'}
                      alt="Profile"
                      style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                    />
                  </TableCell>
                  <TableCell align="left">{user.userId}</TableCell>
                  <TableCell align="left">{`${user.firstName} ${user.lastName}`}</TableCell>
                  <TableCell align="left">{user.phone}</TableCell>
                  <TableCell align="left">{user.email}</TableCell>
                  <TableCell align="left">
                    <select
                      onChange={(e) => handleSelectPlan(user.userId, e.target.value)}
                      value={user.selectedPlanId || ''}
                    >
                      <option value="" disabled>
                        Select Plan
                      </option>
                      {plans.length === 0 ? (
                        <option disabled>No Plans Available</option>
                      ) : (
                        plans.map((plan) => (
                          <option key={plan._id} value={plan._id}>
                            {plan.name} - {plan.finalPrice} USD
                          </option>
                        ))
                      )}
                    </select>
                    <div className="button-group">
                      <button
                        onClick={() => handleTogglePlan(user.userId, user.selectedPlanId, plans.find(plan => plan._id === user.selectedPlanId)?.active)}
                        className="activate-button"
                      >
                        {plans.find(plan => plan._id === user.selectedPlanId)?.active ? 'Deactivate' : 'Activate'}
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
    </div>
  );
};

export default SparklePlanActivateDeactivate;
