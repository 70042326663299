import React, { useState, useEffect } from "react";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import CreateIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import "./ManageSparkel.css"; // Ensure your CSS styles are in this file
import { API_URL } from '../utils';

const ManageSparkel = () => {
  const [sparkels, setSparkels] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    intensity: 0,
    duration: 0,
    price: 0,
    sparkle_count: 0,
    couponCode: "",
    discountPercentage: 0,
    expiryDate: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [currentSparkelId, setCurrentSparkelId] = useState(null);

  useEffect(() => {
    fetchSparkels();
  }, []);

  const fetchSparkels = async () => {
    try {
      const token = localStorage.getItem("user_token");
      const response = await axios.get(`${API_URL}/super-admin/sparkle-lists`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSparkels(response.data.sparkles || []);
    } catch (error) {
      console.error("Error fetching sparkels:", error);
      setSparkels([]);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editMode) {
      await updateSparkel();
    } else {
      await createSparkel();
    }
    resetForm();
  };

  const createSparkel = async () => {
    try {
      const token = localStorage.getItem("user_token");
      await axios.post(`${API_URL}/super-admin/sparkles/create`, {
        ...formData,
        expiryDate: formData.expiryDate.replace("-", "/"),
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchSparkels();
    } catch (error) {
      console.error("Error creating sparkel:", error);
    }
  };

  const updateSparkel = async () => {
    try {
      const token = localStorage.getItem("user_token");
      await axios.put(`${API_URL}/super-admin/sparkles/update/${currentSparkelId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchSparkels();
    } catch (error) {
      console.error("Error updating sparkel:", error);
    }
  };

  const handleEditSparkel = (sparkel) => {
    setFormData({
      name: sparkel.name,
      type: sparkel.type,
      intensity: sparkel.intensity,
      duration: sparkel.duration,
      price: sparkel.price,
      sparkle_count: sparkel.sparkle_count,
      couponCode: sparkel.coupon.code,
      discountPercentage: sparkel.coupon.discountPercentage,
      expiryDate: sparkel.coupon.expiryDate.split("T")[0],
    });
    setEditMode(true);
    setCurrentSparkelId(sparkel._id);
  };

  const handleDeleteSparkel = async (id) => {
    try {
      const token = localStorage.getItem("user_token");
      await axios.delete(`${API_URL}/super-admin/sparkles/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchSparkels();
    } catch (error) {
      console.error("Error deleting sparkel:", error);
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      type: "",
      intensity: 0,
      duration: 0,
      price: 0,
      sparkle_count: 0,
      couponCode: "",
      discountPercentage: 0,
      expiryDate: "",
    });
    setEditMode(false);
    setCurrentSparkelId(null);
  };

  return (
    <div className="manage-sparkel-container">
      <div className="flex-container">
        <div className="form-container mb-8 bg-white p-4 rounded shadow">
          <h2 className="create-sparkel-text text-2xl mb-4">
            {editMode ? "Edit Sparkel" : "Create Sparkel"}
          </h2>
          <form onSubmit={handleSubmit} className="sparkel-form space-y-4">
            {/* Form Fields */}
            <div className="form-group">
              <label htmlFor="name">Sparkel Name</label>
              <input
                type="text"
                name="name"
                placeholder="Sparkel Name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="type">Type</label>
              <input
                type="text"
                name="type"
                placeholder="Type"
                value={formData.type}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="intensity">Intensity</label>
              <input
                type="number"
                name="intensity"
                placeholder="Intensity"
                value={formData.intensity}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="duration">Duration (minutes)</label>
              <input
                type="number"
                name="duration"
                placeholder="Duration (minutes)"
                value={formData.duration}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="price">Price</label>
              <input
                type="number"
                name="price"
                placeholder="Price"
                value={formData.price}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="sparkle_count">Sparkle Count</label>
              <input
                type="number"
                name="sparkle_count"
                placeholder="Sparkle Count"
                value={formData.sparkle_count}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="couponCode">Coupon Code</label>
              <input
                type="text"
                name="couponCode"
                placeholder="Coupon Code"
                value={formData.couponCode}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="form-group">
              <label htmlFor="discountPercentage">Discount Percentage</label>
              <input
                type="number"
                name="discountPercentage"
                placeholder="Discount Percentage"
                value={formData.discountPercentage}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="form-group">
              <label htmlFor="expiryDate">Expiry Date</label>
              <input
                type="date"
                name="expiryDate"
                value={formData.expiryDate}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={editMode ? <SaveIcon /> : <CreateIcon />}
              >
                {editMode ? "Update" : "Create"}
              </Button>
            </div>

            {editMode && (
              <Button
                type="button"
                onClick={resetForm}
                variant="contained"
                color="secondary"
                startIcon={<CancelIcon />}
              >
                Cancel
              </Button>
            )}
          </form>
        </div>

        <div className="sparkel-list">
          <h2 className="text-2xl font-bold mb-4">Existing Sparkels</h2>
          <div className="grid">
            {sparkels && sparkels.length > 0 ? (
              sparkels.map((sparkel) => (
                <div key={sparkel._id} className="sparkel-item p-4 border border-gray-300 rounded shadow">
                  <p>Name: {sparkel.name}</p>
                  <p>Type: {sparkel.type}</p>
                  <p>Intensity: {sparkel.intensity}</p>
                  <p>Duration: {sparkel.duration} minutes</p>
                  <p>Price: ${sparkel.price}</p>
                  <p>Sparkle Count: {sparkel.sparkle_count}</p>
                  <p>Coupon Code: {sparkel.coupon.code}</p>
                  <p>Discount Percentage: {sparkel.coupon.discountPercentage}%</p>
                  <p>Expiry Date: {sparkel.coupon.expiryDate.split("T")[0]}</p>
                  <Stack direction="row" gap={1} className="mt-4">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<EditIcon />}
                      onClick={() => handleEditSparkel(sparkel)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDeleteSparkel(sparkel._id)}
                    >
                      Delete
                    </Button>
                  </Stack>
                </div>
              ))
            ) : (
              <p>No sparkels found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSparkel;
