import React from 'react';
import './SelectBar.css';

const SelectBar = ({ status, setStatus, startDate, setStartDate, endDate, setEndDate }) => {

  return (
    <div className="select-bar-container">
      <div className="select-bar">
        <select value={status} onChange={(e) => setStatus(e.target.value)}>
          <option value="">Select Status</option>
          <option value="active">Active</option>
        <option value="banned">Banned</option>
        </select>
        
        <input 
          type="text" 
          placeholder="Search By Name" 
        />
        
        <input 
          type="text" 
          placeholder="Search By Email" 
          // -> refetch 
        />
        
        <input 
          type="text" 
          placeholder="Search By userid" 
        />
        
        <input 
          type="text" 
          placeholder="Search By mobile no." 
        />
        
        <input 
          type="date" 
          value={startDate} 
          onChange={(e) => setStartDate(e.target.value)} 
        />
        
        <input 
          type="date" 
          value={endDate} 
          onChange={(e) => setEndDate(e.target.value)} 
        />
        
        <button className="search-button">Search</button>
        <button className="reset-button">Reset</button>
      </div>
       
      <div className='date'>
        <input 
          type="date" 
          value={startDate} 
          onChange={(e) => setStartDate(e.target.value)} 
        />
          
        <input 
          type="date" 
          value={endDate} 
          onChange={(e) => setEndDate(e.target.value)} 
        />
      </div>
      
      <button className="download-button">Download All Users Details</button>
    </div>
  );
};

export default SelectBar;
