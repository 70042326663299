import React, { useEffect, useState } from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import axios from "axios";
import { API_URL } from '../utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';

const makeStyle = (status) => {
  switch (status.toLowerCase()) {
    case "approved":
      return { color: "green" };
    case "rejected":
      return { color: "#ff4d4d" };
    default:
      return { color: "#ff8c00" }; // Pending
  }
};

const DocumentVerification = () => {
  const [status, setStatus] = useState('Pending');
  const [documentsData, setDocumentsData] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState({ name: "", email: "", userId: "", phone: "" });
  const apiBaseUrl = API_URL;
  const navigate = useNavigate();

  const fetchDocuments = async () => {
    let endpoint = '';
    switch (status) {
      case 'Pending':
        endpoint = 'pending-verification-documents';
        break;
      case 'Approved':
        endpoint = 'approved-documents';
        break;
      case 'Rejected':
        endpoint = 'rejected-documents';
        break;
      default:
        return;
    }

    try {
      const response = await axios.get(`${apiBaseUrl}/super-admin/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      setDocumentsData(response.data);
    } catch (error) {
      console.error("Error fetching document data:", error);
    }
  };

  useEffect(() => {
    if (status) {
      fetchDocuments();
    }
  }, [status]);

  const handleSearch = async () => {
    let response;
    const { email, phone, name, userId } = searchCriteria;
    try {
      if (email) {
        response = await axios.post(
          `${apiBaseUrl}/super-admin/search-user-by-email`,
          { email },
          { headers: { Authorization: `Bearer ${localStorage.getItem("user_token")}` } }
        );
        setDocumentsData([response.data]);
      } else if (phone) {
        response = await axios.post(
          `${apiBaseUrl}/super-admin/search-user-by-phone`,
          { phone },
          { headers: { Authorization: `Bearer ${localStorage.getItem("user_token")}` } }
        );
        setDocumentsData([response.data]);
      } else if (name) {
        const [firstName, lastName] = name.split(" ");
        response = await axios.post(
          `${apiBaseUrl}/super-admin/search-user-by-name`,
          { firstName, lastName },
          { headers: { Authorization: `Bearer ${localStorage.getItem("user_token")}` } }
        );
        setDocumentsData(response.data);
      } else if (userId) {
        response = await axios.post(
          `${apiBaseUrl}/super-admin/search-user-by-id`,
          { userId },
          { headers: { Authorization: `Bearer ${localStorage.getItem("user_token")}` } }
        );
        setDocumentsData([response.data]);
      }
    } catch (error) {
      console.error("Error searching user:", error);
    }
  };

  const handleReset = () => {
    setStatus('');
    setSearchCriteria({ email: '', name: '', userId: '', phone: '' });
    setDocumentsData([]);
  };

  const handleViewRequest = (userId) => {
    navigate(`/document-verification-request/${userId}`);
  };

  // Function to render the verification status with document numbers and icons
  const renderVerificationStatus = (documents) => {
    return documents.map((doc, index) => (
      <div key={doc._id} style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}>
        {`Document ${index + 1} `}
        {doc.verificationStatus.toLowerCase() === "approved" ? (
          <CheckCircleIcon style={{ color: "green", marginLeft: "5px" }} />
        ) : (
          <CancelIcon style={{ color: "#ff4d4d", marginLeft: "5px" }} />
        )}
      </div>
    ));
  };

  return (
    <div className="MainDash">
      <h1>Document Verification</h1>
      <div className="select-bar-container">
        <div className="select-bar">
          <select value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value="">Select Status</option>
            <option value="Pending">Pending</option>
            <option value="Approved">Approved</option>
            <option value="Rejected">Rejected</option>
          </select>

          <input
            type="text"
            placeholder="Search By Name"
            value={searchCriteria.name}
            onChange={(e) => setSearchCriteria({ ...searchCriteria, name: e.currentTarget.value })}
          />

          <input
            type="text"
            placeholder="Search By Email"
            value={searchCriteria.email}
            onChange={(e) => setSearchCriteria({ ...searchCriteria, email: e.currentTarget.value })}
          />

          <input
            type="text"
            placeholder="Search By User ID"
            value={searchCriteria.userId}
            onChange={(e) => setSearchCriteria({ ...searchCriteria, userId: e.currentTarget.value })}
          />

          <input
            type="text"
            placeholder="Search By Phone"
            value={searchCriteria.phone}
            onChange={(e) => setSearchCriteria({ ...searchCriteria, phone: e.currentTarget.value })}
          />

          <div className="button-group">
            <button onClick={handleSearch} className="search-button">Search</button>
            <button onClick={handleReset} className="reset-button">Reset</button>
          </div>
        </div>
      </div>
      <div className="Table">
        <h3>Document List</h3>
        <div className="table-container">
          <TableContainer
            component={Paper}
            style={{ boxShadow: "0px 13px 20px 0px #80808029", overflowY: "auto", height: 400 }}
          >
            <Table stickyHeader sx={{ minWidth: 800 }} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#1F618D" }}>
                <TableRow>
                  <TableCell align="left">User ID</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Mobile</TableCell>
                  <TableCell align="left">Verification Status</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ color: "white" }}>
                {documentsData.map((row) => (
                  <TableRow key={row.userId} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="left">{row.userId}</TableCell>
                    <TableCell component="th" scope="row">
                      {`${row.userName}`}
                    </TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.phone}</TableCell>
                    <TableCell align="left">
                      {renderVerificationStatus(row.documents)}
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleViewRequest(row.userId)}
                      >
                        View Request
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default DocumentVerification;
