// src/components/HomePage.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../utils";
import 'bootstrap/dist/css/bootstrap.min.css';
import './HomePage.css'; // Import the CSS file for custom styles

const HomePage = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [blockedUsers, setBlockedUsers] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [apkFile, setApkFile] = useState(null);
  const [version, setVersion] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/super-admin/all-users`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("user_token")}` }
        });
        const users = response.data;

        setTotalUsers(users.length);

        const blocked = users.filter(user => user.status === "blocked").length;
        const active = users.filter(user => user.status === "active").length;

        setBlockedUsers(blocked);
        setActiveUsers(active);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };

    fetchData();
  }, []);

  const handleApkUpload = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('apk', apkFile);
    formData.append('version', version);
    formData.append('forceUpdate', forceUpdate);

    try {
      await axios.post(`${API_URL}/super-admin/upload-apk`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("user_token")}`
        }
      });
      alert('APK uploaded successfully');
    } catch (error) {
      console.error("Failed to upload APK", error);
      alert('Failed to upload APK');
    }
  };

  return (
    <div className="container mt-5">
      <div className="text-center">
        <h1>Welcome to the Homepage!</h1>
        <p>Here you can find the latest updates and information about the application.</p>
      </div>
      <div className="row mt-4">
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <h2 className="card-title">Total Users</h2>
              <p className="card-text display-4">{totalUsers}</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <h2 className="card-title">Blocked Users</h2>
              <p className="card-text display-4">{blockedUsers}</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <h2 className="card-title">Active Users</h2>
              <p className="card-text display-4">{activeUsers}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5 justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title text-center">Upload APK</h2>
              <form onSubmit={handleApkUpload}>
                <div className="row justify-content-center">
                  <div className="col-md-5">
                    <div className="form-group">
                      <label htmlFor="apk">APK File</label>
                      <input 
                        type="file" 
                        className="form-control" 
                        id="apk" 
                        onChange={(e) => setApkFile(e.target.files[0])}
                        required 
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group">
                      <label htmlFor="version">Version</label>
                      <input 
                        type="text" 
                        className="form-control" 
                        id="version" 
                        value={version} 
                        onChange={(e) => setVersion(e.target.value)}
                        required 
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group form-check text-center">
  <div className="d-flex align-items-center justify-content-center">
    <input 
      type="checkbox" 
      className="form-check-input" 
      id="forceUpdate" 
      checked={forceUpdate} 
      onChange={(e) => setForceUpdate(e.target.checked)} 
    />
    <label htmlFor="forceUpdate" className="ml-5"> Force Upload</label>
  </div>
</div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary">Upload</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
