import React, { useEffect, useState } from 'react';
import axios from 'axios';

const QueryMessages = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get('https://admin.hisathi.com/api/hisathi-user');
        setMessages(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchMessages();
  }, []);

  const containerStyle = {
    width: '90%', // Fluid width
    maxWidth: '1000px', // Maximum width for larger screens
    margin: '40px auto',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    maxHeight: '100vh',
    overflowY: 'auto',
  };

  const titleStyle = {
    fontSize: '28px',
    marginBottom: '20px',
    textAlign: 'center',
    color: '#333',
  };

  const listStyle = {
    listStyle: 'none',
    padding: '0',
  };

  const listItemStyle = {
    padding: '15px',
    borderBottom: '1px solid #ddd',
    transition: 'background-color 0.3s',
  };

  const listItemHeaderStyle = {
    fontWeight: 'bold',
    color: '#555',
  };

  const listItemTextStyle = {
    fontWeight: 'normal',
    color: '#333',
  };

  const listItemHoverStyle = {
    backgroundColor: '#f0f0f0',
  };

  if (loading) {
    return <p style={{ textAlign: 'center' }}>Loading...</p>;
  }

  if (error) {
    return <p style={{ textAlign: 'center', color: 'red' }}>Error loading messages: {error.message}</p>;
  }

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Query Messages</h1>
      {messages.length === 0 ? (
        <p style={{ textAlign: 'center', color: '#777' }}>No messages found.</p>
      ) : (
        <ul style={listStyle}>
          {messages.map((message, index) => (
            <li
              key={index}
              style={listItemStyle}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = listItemHoverStyle.backgroundColor}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
            >
              <p style={listItemHeaderStyle}>Name: <span style={listItemTextStyle}>{message.name}</span></p>
              <p style={listItemHeaderStyle}>Email: <span style={listItemTextStyle}>{message.email}</span></p>
              <p style={listItemHeaderStyle}>Phone Number: <span style={listItemTextStyle}>{message.phoneNumber}</span></p>
              <p style={listItemHeaderStyle}>Message: <span style={listItemTextStyle}>{message.message}</span></p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default QueryMessages;

// Add this CSS in your CSS file or as a style tag in your component
const styles = `
@media (max-width: 600px) {
  h1 {
    font-size: 20px;
  }

  li {
    padding: 10px;
  }

  p {
    font-size: 14px;
  }
}
`;

document.head.insertAdjacentHTML('beforeend', `<style>${styles}</style>`);
